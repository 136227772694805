// RegisterScreen.js
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";

import dateHelpers from "@library/common/helpers/misc/date";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useDispatch } from "siteFunctions/hooks";
import Base from "../Base";
import ButtonPanel from "../ButtonPanel";
import _ from "lodash";
import {
  selectors,
  actions,
  components,
  // database,
  // helpers,
  hooks,
  // riskStores,
  mtaCar,
} from "siteStore";
import services from "siteService";
import config from "config";
const { SessionSavingChecker } = components.session;

import {
  useLocation,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import {
  ButtonStyledMta,
  LoadingRow,
  PanelMta,
  Error,
  ButtonToggleMta,
  ConfirmModalButton,
  CardBorder,
  DevContainer,
} from "siteComponents";
import PageSecureLayout from "siteLayouts/Page/Secure";
import SalusChangeData from "../../Components/Debug/SalusChangeData";

// import { mtaStore , getProps} from "../../common";

// ********************************************************
// MAIN COMPONENT
// ********************************************************

const MTAChangeView = (props) => {
  const [isInit, setIsInit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSalusChanges, setShowSalusChanges] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const isFirstVisit = location?.state?.isFirstVisit;

  const context = useOutletContext();
  const {
    functions,
    mtaStore,
    getProps,
    editComponents = [],
    riskStore,
    selectors,
    fnGetChangeData,
    controls: { fullscreen: fullscreenControls },
  } = context;

  const useSnapshotAutoReset = useRef(true);
  const isPagerDataMissing = !editComponents || editComponents.length === 0;

  const fnDisableAutoUndo = async () => {
    useSnapshotAutoReset.current = false;
  };

  const snapshotControls = hooks.snapshotMtaCar.useSnapshotControls(dispatch);

  const baseSalusData = useSelector(
    selectors.getBaseSalus,
    require("lodash").isEqual
  );
  const salusData = useSelector(
    selectors.riskStore.getSalusData,
    require("lodash").isEqual
  );
  const isStoreValid = useSelector(
    mtaStore.selectors.riskStore.getIsStoreValid,
    require("lodash").isEqual
  );
  const changeData = fnGetChangeData(salusData, baseSalusData);

  const isPageValid = isStoreValid && changeData.isChanged;

  if (false) {
    const riskValues = useSelector((state) =>
      selectors.riskStore.getValue(state, [
        ...["RegNumber", "Manufacturer", "ModelDescription"].map(
          (x) => `Risk/Vehicle/${x}`
        ),
        ...[
          "HouseNameOrNumber",
          "Postcode",
          "AddressLine1",
          "AddressLine2",
          "AddressLine3",
          "AddressLine4",
        ].map((x) => `Risk/Proposer/Address/${x}`),
        "Risk/TotalMileage",
      ])
    );
    const riskSets = useSelector((state) =>
      selectors.riskStore.getValue(state, ["Risk/AdditionalInsuredSet"])
    );
    const riskStoreData = useSelector((state) => state?.mtaRiskCar);

    console.log("Change.jsx", { riskStoreData, riskValues, riskSets });
  }

  // **********************************
  // Error hooks
  // **********************************
  const useErrorHelpers = editComponents.map((x, i) => {
    const [_showErrors, _setShowErrors] = useState(false);
    console.log("ERROR", i, _showErrors);

    const useShowErrorHelper = (isValid) => {
      const [isMounted, setIsMounted] = useState(false);

      useEffect(() => {
        if (!isMounted) return;
        if (isValid) _setShowErrors(false);
      }, [isValid]);

      useEffect(() => {
        if (!isMounted) return;
        if (showError) _setShowErrors(true);
      }, [showError]);

      useEffect(() => {
        setIsMounted(true);
        return () => {
          _setShowErrors(false);
        };
      }, []);

      return _showErrors;
    };
    return { hook: useShowErrorHelper, set: _setShowErrors };
  });
  // **********************************
  // Functions
  // **********************************
  const fnGoToPrev = async () => {
    await snapshotControls.undo();
    await fnDisableAutoUndo();
    await functions.navigation.start();
  };

  const fnGoToNext = async () => {
    fnShowErrors();

    if (!isPageValid) return;

    // Run "onPagerEnd()" for each Applet
    editComponents.forEach(async (x) => {
      if (x.onPagerEnd) await x.onPagerEnd({ dispatch, context });

      if (x.additionalApplets) {
        x.additionalApplets.forEach(async (y) => {
          if (y.onPagerEnd) await y.onPagerEnd({ dispatch, context });
        });
      }
    });

    await snapshotControls.commit();
    await fnDisableAutoUndo();
    await functions.sessionSave();
    await functions.navigation.quote();
  };

  const fnShowErrors = async () => {
    setShowError(true);

    useErrorHelpers.forEach((x) => x.set(true));

    editComponents
      .filter((x) => x.validationGroup)
      .forEach(async ({ validationGroup }) => {
        // console.log("dadadad", validationGroup, riskStore.actions);

        if (riskStore.actions.update) {
          //LEGACY
          await dispatch(
            riskStore.actions.update(
              {
                errorsShow: true,
                group: validationGroup,
                runRules: false,
              },
              undefined,
              true
            )
          );
        }

        if (riskStore.actions.updateErrorShowByGroup) {
          //NEW
          await dispatch(
            riskStore.actions.updateErrorShowByGroup({
              value: true,
              group: validationGroup,
            })
          );
        }

        //
      });

    // const response = await dispatch(
    //   mtaStore.actions.updateRisk(
    //     editComponents
    //       .filter((x) => x.validationGroup)
    //       .map(({ validationGroup }) => {
    //         return {
    //           errorsShow: true,
    //           group: validationGroup,
    //           runRules: false,
    //         };
    //       }),
    //     undefined,
    //     true
    //   )
    // );
    // console.log("dada",response)
  };
  // **********************************
  // Effects
  // **********************************
  useEffect(() => {
    const myFn = async () => {
      //console.log("riskState", riskState);
      //Risk/Vehicle/RegNumber
      await snapshotControls.create();

      editComponents.forEach(async (x) => {
        if (x.onPagerStart)
          await x.onPagerStart({ dispatch, isFirstVisit, context });

        if (x.additionalApplets) {
          x.additionalApplets.forEach(async (y) => {
            if (y.onPagerStart)
              await y.onPagerStart({ dispatch, isFirstVisit, context });
          });
        }
      });

      setIsInit(true);
    };
    myFn();

    return async () => {
      // If we HAVEN'T clicked the button, then auto RESET on unload
      if (useSnapshotAutoReset.current === true) {
        await snapshotControls.reset();
      }

      fullscreenControls.clear(); //#2956
    };
  }, []);

  // **********************************
  // Return
  // **********************************
  if (!isInit) return null;

  console.log("CHANGE.jsx", { editComponents });

  if (fullscreenControls.enabled) return fullscreenControls.data;

  return (
    <Base
      className="mta-change"
      headingText="Make a change to my policy"
      descriptionContent="Tell us about the changes you want to make"
      isChange={true}
      data-cy="layout-mta-change"
    >
      {!fullscreenControls.enabled && (
        <>
          {editComponents.map(
            (
              {
                Component,
                heading,
                additionalApplets = [],
                additionalAppletsHeading = "You may be interested in the following:",
                additionalAppletsEnabled = () => true,
              },
              i
            ) => {
              return (
                <div key={i} className="mta-edit-item mb-3">
                  {/* <PanelMta header={heading || "xxx "}> */}
                  <Component
                    // fnFullscreenSet={fullscreenControls.set}
                    // fnFullscreenClear={fullscreenControls.clear}
                    additionalAppletsHeading={additionalAppletsHeading}
                    additionalApplets={
                      additionalAppletsEnabled(salusData) &&
                      additionalApplets.map((x) => x.Component)
                    }
                    useShowErrorHelper={useErrorHelpers[i].hook}
                  />
                  {/* </PanelMta> */}
                </div>
              );
            }
          )}

          <ButtonPanel
            onPrev={fnGoToPrev}
            prevLabel="Back"
            onNext={fnGoToNext}
            nextLabel="Next"
            errorMsg={(function () {
              if (!showError) return undefined;
              if (!isStoreValid) return "Please correct the errors above";
              if (!changeData.isChanged)
                return "Please make changes to proceed";

              return undefined;
            })()}
            className="mb-3"
          />
        </>
      )}

      <DevContainer heading="Change data (Which will be shown to the CLIENT)">
        {changeData?.displayList?.map(({ heading, data, key }) => {
          return (
            <div className="row mta-change-item-container" key={key}>
              {heading && (
                <div className="col-12 heading-secondary">{heading}</div>
              )}
              {data.map((subData, i) => (
                <div className="col-12" key={i}>
                  {subData}
                </div>
              ))}
            </div>
          );
        })}
      </DevContainer>

      <DevContainer heading="SALUS change data">
        {!showSalusChanges && (
          <ButtonStyledMta onClick={() => setShowSalusChanges(true)}>
            Show SalusChanges
          </ButtonStyledMta>
        )}
        {showSalusChanges && (
          <div>
            <ButtonStyledMta onClick={() => setShowSalusChanges(true)}>
              HIDE SalusChanges
            </ButtonStyledMta>
            <SalusChangeData />
          </div>
        )}
      </DevContainer>
    </Base>
  );
};

export default MTAChangeView;
